/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui'
import { ReactNode } from 'react'
import Slider, { Settings } from 'react-slick'
import 'slick-carousel/slick/slick.css'

type Props = {
  variant?: 'default' | 'primary'
  data: readonly ReactNode[]
  settings?: Settings
  sx?: object
}

const appendDots = (dots: readonly any[]) => (
  <Flex
    as="ul"
    sx={{
      listStyleType: 'none',
      justifyContent: 'center',
      mt: 45,
    }}
  >
    {dots.map((item, index) => {
      const isActive = item.props.className === 'slick-active'
      return (
        <Box
          as="li"
          key={index}
          sx={{
            '> button': {
              outline: 'none',
              opacity: isActive ? 1 : 0.5,
              borderWidth: isActive ? '1.5px' : '0',
              borderStyle: 'solid',
              borderColor: 'textMid',
              borderRadius: '50%',
              position: 'relative',
              bg: 'transparent',
              color: 'transparent',
              width: '40px',
              height: '40px',
              cursor: 'pointer',
              '::before': {
                content: '""',
                display: 'block',
                bg: 'textMid',
                width: '7px',
                height: '7px',
                transform: 'translate(-50%, -50%) matrix(0.71,-0.71,0.71,0.71,0,0)',
                position: 'absolute',
                top: '50%',
                left: '50%',
              },
            },
          }}
        >
          {item.props.children}
        </Box>
      )
    })}
  </Flex>
)

const ProductCarousel = ({ data = [], variant = 'default', settings = {}, sx = {} }: Props) => {
  return (
    <Slider
      {...{
        dots: true,
        arrows: false,
        appendDots,
        ...settings,
      }}
      sx={{
        ...(variant === 'primary' && {
          '.slick-dots': {
            position: 'absolute',
            zIndex: 1,
            bottom: 50,
            left: '50%',
            transform: 'translateX(-50%)',
            pl: 0,
            button: {
              borderColor: 'main',
              '::before': {
                bg: 'main',
              },
            },
          },
        }),
        ...sx,
      }}
    >
      {data.map((Item, index) => {
        // @ts-ignore
        return <Item key={index} />
      })}
    </Slider>
  )
}

export default ProductCarousel
