import React from 'react'
import { graphql } from 'gatsby'
import AntiqueShowroomTemplate, { AntiqueShowroomProps } from '~/templates/antique-showroom'

export const query = graphql`
  query {
    pageData: markdownRemark(frontmatter: { templateKey: { eq: "antiques-showroom" } }) {
      frontmatter {
        headerVariant
        header {
          title
          description
          image {
            ...heroImage
          }
          imageMobile {
            ...mobileHeroImage
          }
        }
        showroomTitle
        headerQuote
        address {
          line1
          line2
        }
        carousel {
          image {
            ...heroImage
          }
        }
      }
      html
    }
  }
`

type Props = {
  data: {
    pageData: AntiqueShowroomProps['data']
  }
}

const AntiquesShowroom = ({ data: { pageData } }: Props) => (
  <AntiqueShowroomTemplate data={pageData} />
)

export default AntiquesShowroom
