// @ts-ignore
import React, { FC } from 'react'
import { BaseStyles, Box, Container, Heading, Text } from 'theme-ui'
import { GatsbyImageType } from '~/@types/models'
import MarkdownIt from '~/components/MarkdownIt'
import HeroImage from '~/components/hero-image'
import Layout from '~/components/layout'
import ProductCarousel from '~/components/products-carousel'
import SliderArrowCustom from '~/components/products-carousel/chevron-arrow'
import AntiquesShowroomOpenings from '~/containers/antiques-showroom-openings'

export type AntiqueShowroomProps = {
  data: {
    frontmatter: {
      header: {
        title: string
        description: string
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }
      showroomTitle: string
      headerQuote: string
      address: {
        line1: string
        line2: string
      }
      carousel: ReadonlyArray<{
        image: GatsbyImageType
      }>
    }
    html: string
  }
}

const AntiqueShowroom: FC<AntiqueShowroomProps> = ({ data }) => {
  const {
    html: content,
    frontmatter: { header, carousel, headerQuote },
  } = data

  return (
    <Layout title={header.title} description={header.description}>
      <HeroImage
        images={{
          default: header.image,
        }}
        height="primary"
      >
        <Container
          sx={{
            textAlign: [null, 'center'],
          }}
        >
          <Heading
            as="h2"
            variant="title2"
            sx={{
              color: 'inherit',
              variant: 'text.h0',
              mb: 27,
            }}
          >
            {header.title}
          </Heading>
          <Container
            sx={{
              maxWidth: 576,
            }}
          >
            <Text
              variant="regular"
              sx={{
                color: 'inherit',
                display: ['none', 'block'],
              }}
            >
              {header.description}
            </Text>
          </Container>
        </Container>
      </HeroImage>
      <Container
        variant="containerPrimary"
        sx={{
          my: [50, null, 100],
        }}
      >
        <Text
          sx={{
            display: ['block', null, 'none'],
            mb: 'md1',
          }}
          variant="regular"
        >
          {header.description}
        </Text>
        <Box mb={['l', null, 'xl']}>
          <Text as="blockquote" variant="regular">
            &ldquo;{headerQuote}&ldquo; - Ben Soleimani
          </Text>
        </Box>
      </Container>

      <ProductCarousel
        variant="primary"
        settings={{
          dots: false,
          arrows: true,
          nextArrow: <SliderArrowCustom position="right" />,
          prevArrow: <SliderArrowCustom />,
        }}
        data={carousel.map(item => {
          return () => (
            <HeroImage
              images={{
                default: item.image,
              }}
              height="auto"
            />
          )
        })}
      />

      <Container variant="containerPrimary">
        <Box my={['l', null, 'xl']}>
          <BaseStyles>
            <MarkdownIt content={content} />
          </BaseStyles>
          <AntiquesShowroomOpenings addressInTable={true} />
        </Box>
      </Container>
    </Layout>
  )
}

export default AntiqueShowroom
