import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Openings from '~/components/openings'

type QueryResult = {
  showroom: {
    frontmatter: {
      showroomTitle: string
      openHours: Array<{
        day: string
        time: string
      }>
      address: {
        line1: string
        line2: string
      }
    }
  }
}

const showroomQuery = graphql`
  query {
    showroom: markdownRemark(frontmatter: { templateKey: { eq: "antiques-showroom" } }) {
      frontmatter {
        showroomTitle
        openHours {
          day
          time
        }
        address {
          line1
          line2
        }
      }
    }
  }
`

type Props = {
  addressInTable?: boolean
}

const AntiquesShowroomOpenings: FC<Props> = props => {
  const {
    showroom: {
      frontmatter: { showroomTitle, openHours, address },
    },
  } = useStaticQuery<QueryResult>(showroomQuery)

  return <Openings openHours={openHours} title={showroomTitle} address={address} {...props} />
}

export default AntiquesShowroomOpenings
